@import "../../shared/styles/vars";
@import "../../shared/styles/mixins";

.about-nft {
  &__header {
    background-image: url("../../assets/images/aboutNft/about-nft-header.png");
    color: #ff00ff;
  }

  &__content {
    width: 100%;

    .nft-card {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      color: black;
      padding: 51px 210px 64px;
      gap: 64px;

      @include breakpoint_MaxWidth($bp-1680) {
        padding: 51px 130px 64px;
      }
      @include breakpoint_MaxWidth($bp-1536) {
        padding: 51px 70px 64px;
      }
      @include breakpoint_MaxWidth($bp-1440) {
        padding: 36px 36px 48px;
        gap: 48px;
      }
      @include breakpoint_MaxWidth($bp-1330) {
        padding: 27px 24px 24px;
        gap: 24px;
      }
      @include breakpoint_MaxWidth($mobile-album) {
        flex-direction: column;
        align-items: center;
      }
      @include breakpoint_MaxWidth($mobile-l) {
        padding: 30px 16px 36px;
      }

      &__gallery {
        padding-top: 13px;

        @include breakpoint_MaxWidth($mobile-album) {
          padding-top: 0;
        }

        &__image-wrapper {
          width: 572px;
          height: 629px;
          position: relative;

          @include breakpoint_MaxWidth($bp-1280) {
            height: 424px;
            width: 385px;
          }
          @include breakpoint_MaxWidth($bp-900) {
            width: 306px;
            height: 336px;
          }
          @include breakpoint_MaxWidth($mobile-765) {
            width: 235px;
            height: 258px
          }
          @include breakpoint_MaxWidth($mobile-album) {
            width: 328px;
            height: 360px;
          }
          @include breakpoint_MaxWidth($mobile-xs) {
            width: 300px;
            height: 330px;
          }
        }

        &__image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__info {
        height: 100%;
        width: 100%;
        max-width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &_nft-name {
          font-family: Joystix, sans-serif;
          font-size: 48px;
          line-height: 58px;
          color: #47cffe;
          margin-bottom: 8px;

          @include breakpoint_MaxWidth($bp-1100) {
            font-size: 40px;
            line-height: 48px;
          }
          @include breakpoint_MaxWidth($bp-1024) {
            font-size: 32px;
            line-height: 40px;
          }
          @include breakpoint_MaxWidth($bp-900) {
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 4px;
          }
          @include breakpoint_MaxWidth($mobile-765) {
            font-size: 24px;
            line-height: 28px;
          }
          @include breakpoint_MaxWidth($mobile-album) {
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 9px;
          }
        }

        &_artist-name {
          font-family: Joystix, sans-serif;
          font-size: 36px;
          line-height: 43px;
          color: #fec200;
          margin-bottom: 12px;

          @include breakpoint_MaxWidth($bp-1100) {
            font-size: 30px;
            line-height: 36px;
          }
          @include breakpoint_MaxWidth($bp-1024) {
            font-size: 26px;
            line-height: 30px;
          }
          @include breakpoint_MaxWidth($bp-900) {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 6px;
          }
          @include breakpoint_MaxWidth($mobile-765) {
            font-size: 20px;
            line-height: 24px;
          }
          @include breakpoint_MaxWidth($mobile-album) {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 6px;
          }
        }

        &_interview-link {
          font-size: 24px;
          line-height: 28px;
          text-decoration-line: underline;
          color: #ff00ff;
          cursor: pointer;

          @include breakpoint_MaxWidth($bp-900) {
            font-size: 16px;
            line-height: 19px;
          }

          &:hover {
            color: #ad04ad;
            text-decoration-line: none;
          }
        }

        &_about-card {
          font-size: 18px;
          line-height: 24px;
          margin-top: 16px;
          margin-bottom: 20px;

          @include breakpoint_MaxWidth($bp-900) {
            font-size: 16px;
            line-height: 19px;
          }
        }

        &_votes {
          display: flex;
          gap: 39px;
          margin-bottom: 20px;

          @include breakpoint_MaxWidth($bp-900) {
            gap: 21px;
            margin-bottom: 16px;
          }

          .vote-card {
            display: flex;
            flex-direction: column;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #000000;
            min-height: 0;

            @include breakpoint_MaxWidth($bp-900) {
              font-size: 14px;
              line-height: 16px;
              max-width: 89px;
            }

            &__image-wrapper {
              width: 105px;
              height: 112px;
              border: 1px solid #c4c4c4;
              position: relative;
              margin-bottom: 8px;

              @include breakpoint_MaxWidth($bp-900) {
                width: 89px;
                height: 95px;
                margin-bottom: 10px;
              }
            }

            &__image {
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
            }

            &__time {
              @include breakpoint_MaxWidth($bp-900) {
                display: flex;
                flex-wrap: wrap;
                gap: 4px
              }
            }
          }
        }

        &_market-button {
          margin-bottom: 16px;

          @include breakpoint_MaxWidth($bp-900) {
            margin-bottom: 12px;
          }
          @include breakpoint_MaxWidth($mobile-album) {
            display: flex;
            justify-content: center;
          }

          .ant-btn {
            height: 52px;
            padding-left: 28px;
            padding-right: 28px;
            background: #ff4802;
            color: white;

            @include breakpoint_MaxWidth($mobile-album) {
              width: 100%;
              max-width: 328px;
            }

            &:hover {
              background: #c03500;
            }

            a {
              font-size: 24px;
              line-height: 28px;

              @include breakpoint_MaxWidth($bp-900) {
                font-size: 20px !important;
                line-height: 24px;
              }
              @include breakpoint_MaxWidth($mobile-album) {
                font-size: 24px !important;
                line-height: 28px;
              }
            }
          }
        }

        & .share-block {
          @include breakpoint_MaxWidth($mobile-album) {
            justify-content: center;
          }
        }

        &_share-icons {
          font-size: 24px;
          line-height: 28px;
          color: #ff00ff;
          cursor: pointer;

          &:hover {
            color: #ad04ad;
          }
        }
      }
    }
  }
}
