@import "../../../../shared/styles/vars";
@import "../../../../shared/styles/mixins";

.vote-block {
  background-image: url("../../../../assets/images/main/vote-block-bg.png");
  background-attachment: fixed;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 990px;
  padding: 0 160px;

  @include breakpoint_MaxWidth($bp-1768) {
    padding: 0 130px;
  }
  @include breakpoint_MaxWidth($bp-1680) {
    padding: 0 80px;
  }
  @include breakpoint_MaxWidth($bp-1440) {
    height: 450px;
    background-image: url("../../../../assets/images/main/vote-block-bg-1366.webp");
    background-position: 0 70px
  }
  @include breakpoint_MaxWidth($bp-900) {
    height: unset;
    padding: 36px 117px;
    background-position: 0 42px;
  }
  @include breakpoint_MaxWidth($mobile-album) {
    padding: 36px 80px;
  }
  @include breakpoint_MaxWidth($mobile-xl) {
    padding: 20px 47px 24px;
  }
  @include breakpoint_MaxWidth($mobile-390) {
    background-image: url("../../../../assets/images/main/vote-block-bg-360.webp");
    background-position: -105px;
  }
  @include breakpoint_MaxWidth($mobile-xs) {
    padding: 20px 24px 24px;
  }
}

.vote-body {
  &--voted {
    .vote-card:not(.voted) {
      .vote-card__button {
        margin-bottom: 35px;
      }
    }
  }
}
