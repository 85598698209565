@import "../../../../../../shared/styles/vars";
@import "../../../../../../shared/styles/mixins";

.sort-search-block {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;

  &__sort {
    display: flex;
    color: black;
    min-width: 280px;
    gap: 10px;
    align-items: center;

    @include breakpoint_MaxWidth($bp-1536) {
      min-width: unset;
    }
    @include breakpoint_MaxWidth($mobile-album) {
      width: calc(50% - 12px);
      justify-content: space-between;
      gap: 32px;
    }
    @include breakpoint_MaxWidth($mobile-xl) {
      width: 100%;
    }

    & .ant-select {
      width: 211px;

      @include breakpoint_MaxWidth($bp-1536) {
        width: 170px;
      }
      @include breakpoint_MaxWidth($mobile-album) {
        width: 100%;
      }
    }

    &--label {
      font-size: 18px;
      line-height: 21px;
      white-space: nowrap;

      @include breakpoint_MaxWidth($bp-1440) {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  &__search {
    display: flex;
    width: 100%;
    @include breakpoint_MaxWidth($bp-1280) {
      position: absolute;
      left: 0;
      padding: 0 24px;
      top: 98px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      top: 76px;
    }
    @include breakpoint_MaxWidth($mobile-765) {
      top: 24px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      padding: 0 16px;
    }

    .ant-input {
      min-width: 150px;
      width: 100%;
      max-width: 455px;
      height: 52px;
      border: 2px solid #ff4802;
      padding: 14.5px 16px;
      font-size: 18px;
      line-height: 21px;
      border-radius: 0 !important;

      @include breakpoint_MaxWidth($bp-1280) {
        max-width: unset;
      }

      @include breakpoint_MaxWidth($bp-900) {
        height: 36px;
        padding: 8.5px 16px;
      }

      &:hover,
      &:focus {
        border-color: #ff4802;
      }

      &:placeholder-shown {
        font-size: 16px;
        line-height: 19px;
      }
    }

    .ant-btn {
      height: 52px !important;
      background-color: #ff4802;
      border-color: #ff4802;
      padding: 0;

      @include breakpoint_MaxWidth($bp-900) {
        height: 36px !important;
      }

      &:hover,
      &:focus {
        background-color: #c03500;
        border-color: #c03500;
      }

      span {
        color: white;
        font-size: 16px;
        line-height: 19px;
        padding: 0 32px;

        @include breakpoint_MaxWidth($bp-1440) {
          padding: 0 28px;
        }
        @include breakpoint_MaxWidth($bp-900) {
          padding: 0 17.5px;
        }
        @include breakpoint_MaxWidth($mobile-390) {
          padding: 0 14.5px;
        }
      }
    }
  }
}
