.shadow-button {
  position: relative;
  color: transparent;
  height: 4.25rem;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  width: min-content;
  white-space: nowrap;

  &__top {
    white-space: nowrap;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 4.25rem;
    background-color: white;
    color: #ff00ff;
    line-height: 1.75rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;
    z-index: 2;
    cursor: pointer;
    border: none;
    font: 900 1.5rem Roboto;
    box-shadow: -5px -5px 20px rgba(255, 0, 255, 0.15), -5px -5px 20px rgba(255, 0, 255, 0.15),
      -5px -5px 20px rgba(255, 0, 255, 0.15), -5px -5px 20px rgba(255, 0, 255, 0.15);
    transition: right 0.2s ease-in-out, bottom 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    &:hover {
      bottom: -5px;
      right: -5px;
      box-shadow: -5px -5px 20px rgba(0, 209, 255, 0.15), -5px -5px 20px rgba(0, 209, 255, 0.15),
        -5px -5px 20px rgba(0, 209, 255, 0.15), -5px -5px 20px rgba(0, 209, 255, 0.15);
    }
  }

  &__back {
    white-space: nowrap;
    height: 4.25rem;
    position: absolute;
    background: #00d1ff;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;
    color: #00d1ff;
    right: -5px;
    bottom: -5px;
    z-index: 1;
    box-shadow: 5px 5px 20px rgba(0, 209, 255, 0.15), 5px 5px 20px rgba(0, 209, 255, 0.15),
      5px 5px 20px rgba(0, 209, 255, 0.15), 5px 5px 20px rgba(0, 209, 255, 0.15);
  }

  &.disabled {
    .shadow-button__top {
      bottom: -5px;
      right: -5px;
      box-shadow: none;
      background-color: #e6e6e6;
      color: #b34505;

      &:hover {
        bottom: -5px;
        right: -5px;
        box-shadow: none;
      }
    }

    .shadow-button__back {
      box-shadow: none;
    }
  }

  &.small {
    height: 45px;
    font-size: 18px;
    line-height: 21px;

    .shadow-button__top {
      height: 45px;
      font-size: 18px;
      line-height: 21px;
      &:hover {
        bottom: -3px;
        right: -3px;
      }
    }

    .shadow-button__back {
      height: 45px;
      font-size: 18px;
      line-height: 21px;
      right: -3px;
      bottom: -3px;
    }
  }

  &.danger {
    .shadow-button__top {
      background-color: #ff4d07;
      color: white;
      box-shadow: none;
      &:hover {
        box-shadow: -5px -5px 20px rgba(255, 77, 7, 0.15), -5px -5px 20px rgba(255, 77, 7, 0.15),
          -5px -5px 20px rgba(255, 77, 7, 0.15), -5px -5px 20px rgba(255, 77, 7, 0.15);
      }
    }

    .shadow-button__back {
      background: white;
      color: white;
      box-shadow: 5px 5px 20px rgba(255, 77, 7, 0.15), 5px 5px 20px rgba(255, 77, 7, 0.15),
        5px 5px 20px rgba(255, 77, 7, 0.15), 5px 5px 20px rgba(255, 77, 7, 0.15);
    }
  }

  &.grey {
    .shadow-button__top {
      background-color: #828282;
      color: white;
      box-shadow: none;
      &:hover {
        box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.15), -5px -5px 20px rgba(255, 255, 255, 0.15),
          -5px -5px 20px rgba(255, 255, 255, 0.15), -5px -5px 20px rgba(255, 255, 255, 0.15);
      }
    }

    .shadow-button__back {
      background: white;
      color: white;
      box-shadow: 5px 5px 20px rgba(255, 255, 255, 0.15), 5px 5px 20px rgba(255, 255, 255, 0.15),
        5px 5px 20px rgba(255, 255, 255, 0.15), 5px 5px 20px rgba(255, 255, 255, 0.15);
    }
  }
}
