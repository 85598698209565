@import "../../../../shared/styles/vars";
@import "../../../../shared/styles/mixins";


.about-award-block {
  box-sizing: border-box;
  background-color: black;
  padding: 64px 186px 67px 171px;
  display: flex;
  flex-direction: column;

  @include breakpoint_MaxWidth($bp-1840) {
    padding: 64px 120px 67px;
  }
  @include breakpoint_MaxWidth($bp-1680) {
    padding: 48px 100px;
  }
  @include breakpoint_MaxWidth($bp-1440) {
    padding: 36px 74px 54px;
  }
  @include breakpoint_MaxWidth($bp-1280) {
    padding: 36px 54px 54px;
  }
  @include breakpoint_MaxWidth($bp-1100) {
    padding: 36px 44px 54px;
  }
  @include breakpoint_MaxWidth($bp-1024) {
    padding: 36px 34px 54px;
  }
  @include breakpoint_MaxWidth($bp-900) {
    padding: 24px;
  }
  @include breakpoint_MaxWidth($mobile-album) {
    padding: 24px 16px;
  }


  &__title {
    font-family: Joystix;
    font-size: 48px;
    line-height: 58px;
    color: #47cffe;
    text-align: center;
    margin-bottom: 70px;

    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 29px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 16px;
    }
    @include breakpoint_MaxWidth($mobile-xs) {
      font-size: 23px;
    }
  }

  .image-block {
    padding-left: 0 !important;
    padding-right: 0 !important;
    flex: unset !important;
    max-width: unset !important;

    @include breakpoint_MaxWidth($bp-1440) {
      max-width: 330px !important;
      max-height: 324px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      max-width: 204px !important;
      max-height: 200px !important;
    }
    @include breakpoint_MaxWidth($mobile-album) {
      max-width: 245px !important;
      max-height: 240px !important;
    }
  }

  &__image {
    width: 100%;
    max-width: 397px;
    min-width: 389px;

    @include breakpoint_MaxWidth($bp-1440) {
      object-fit: contain;
      max-width: 100%;
      min-width: unset;
    }

  }

  &__text {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    line-height: 24px;
    color: white;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    max-width: 1100px;

    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 16px;
      line-height: 19px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      font-size: 14px;
      line-height: 16px;
    }

    & p:last-of-type {
      @include breakpoint_MaxWidth($bp-1440) {
        margin-bottom: 30px;
      }
      @include breakpoint_MaxWidth($bp-900) {
        margin-bottom: 16px;
      }
      @include breakpoint_MaxWidth($mobile-album) {
        margin-bottom: 14px;
      }
    }

    a {
      color: #ff00ff;

      &:hover {
        color: #ff00ff;
        text-decoration: underline;
      }
    }

    & div {
      @include breakpoint_MaxWidth($bp-1680) {
        font-size: 20px;
        line-height: 26px;
        flex-grow: unset !important;
      }
      @include breakpoint_MaxWidth($bp-1024) {
        font-size: 16px;
        line-height: 22px;
      }
      @include breakpoint_MaxWidth($bp-900) {
        font-size: 13px;
        line-height: 16px;
      }
      @include breakpoint_MaxWidth($mobile-album) {
        font-size: 16px;
        line-height: 19px;
      }
      &:nth-child(1), :nth-child(2), :nth-child(3) {
        @include breakpoint_MaxWidth($mobile-album) {
          margin-bottom: 14px;
        }
      }
    }
  }

  .ant-row-center {
    margin: 0 !important;
    justify-content: space-between !important;
    gap: 82px;
    padding: 0 !important;
    flex-flow: unset !important;

    @include breakpoint_MaxWidth($bp-1440) {
      gap: 37px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      gap: 24px;
    }
    @include breakpoint_MaxWidth($mobile-album) {
      flex-direction: column !important;
      align-items: center;
      row-gap: 24px !important;
    }
  }

  .ant-col-16 {
    padding: 0 !important;
    flex: unset !important;
    max-width: 1084px;
    width: 100%;

    @include breakpoint_MaxWidth($bp-1440) {
      max-width: 851px;
    }
  }
}
