@import "../../../../../../shared/styles/vars";
@import "../../../../../../shared/styles/mixins";

.date-form {
  display: flex;
  gap: 16px;
  align-items: center;

  @include breakpoint_MaxWidth($bp-900) {
    gap: 8px;
  }

  @include breakpoint_MaxWidth($mobile-album) {
    justify-content: space-between;
    gap: 24px;
  }
  @include breakpoint_MaxWidth($mobile-xl) {
    flex-direction: column;
    gap: 16px;
  }


  .ant-btn {
    height: 52px;
    background-color: #ff4802;
    border-color: #ff4802;
    padding: 0;

    @include breakpoint_MaxWidth($bp-900) {
      height: 36px !important;
    }

    @include breakpoint_MaxWidth($mobile-765) {
      position: absolute;
      top: 128px;
      right: 0;
      margin-right: 24px;
    }
    @include breakpoint_MaxWidth($mobile-xl) {
      top: 230px;
      width: 150px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      margin-right: 16px;
      width: 130px;
    }

    &:hover,
    &:focus {
      background-color: #c03500;
      border-color: #c03500;
    }

    span {
      color: white;
      font-size: 18px;
      line-height: 21px;
      padding-right: 28px;
      padding-left: 28px;

      @include breakpoint_MaxWidth($bp-900) {
        padding-right: 9px;
        padding-left: 9px;
      }

      @include breakpoint_MaxWidth($mobile-765) {
        padding: 0 25px !important;
      }
    }
  }

  &__reset {
    background-color: #47CFFE !important;
    border: none !important;
    cursor: pointer !important;

    @include breakpoint_MaxWidth($mobile-765) {
      right: 131px !important;
    }
    @include breakpoint_MaxWidth($mobile-xl) {
      right: unset !important;
      left: 0 !important;
      margin: 0 0 0 24px !important;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      margin: 0 0 0 16px !important;
    }

    & span {
      font-size: 18px;
      line-height: 21px;
      color: #FFFFFF;
    }

    &:hover {
      background-color: #43879F !important;
    }

    &-disabled {
      background-color: #BDBDBD !important;
      border: none !important;
      cursor: not-allowed !important;

      @include breakpoint_MaxWidth($mobile-765) {
        right: 131px !important;
      }
      @include breakpoint_MaxWidth($mobile-xl) {
        right: unset !important;
        left: 0 !important;
        margin: 0 0 0 24px !important;
      }
      @include breakpoint_MaxWidth($mobile-390) {
        margin: 0 0 0 16px !important;
      }


        & span {
          color: #E0E0E0 !important;
        }
      }
    }

  & .a-select {
    width: unset;
  }

  & .ant-select {
    width: 203px;

    &:first-child {
      margin-right: 8px;

      @include breakpoint_MaxWidth($bp-1536) {
        width: 160px;
      }
      @include breakpoint_MaxWidth($bp-1440) {
        width: 140px;
      }
      @include breakpoint_MaxWidth($mobile-album) {
        width: 100%;
        margin-right: 0;
      }

    }

    &:nth-child(2) {
      @include breakpoint_MaxWidth($bp-1536) {
        width: 180px;
      }
      @include breakpoint_MaxWidth($bp-1440) {
        width: 160px;
      }
      @include breakpoint_MaxWidth($mobile-album) {
        width: 100%;
      }
    }

  }



}
