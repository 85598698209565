@import "../../../../../../../../shared/styles/vars";
@import "../../../../../../../../shared/styles/mixins";


.timer-pad {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__value {
    font-size: 96px;
    line-height: 115px;
    color: #ff00ff;

    @include breakpoint_MaxWidth($bp-1680) {
      font-size: 56px;
      line-height: 62px;
    }
    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 36px;
      line-height: 44px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      font-weight: 400 !important;
      font-size: 24px;
      line-height: 29px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      font-size: 20px;
      line-height: 22px;
    }
  }

  &__label {
    margin-top: 9px;
    font-size: 32px;
    line-height: 38px;
    color: #ff00ff;

    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 28px;
      line-height: 30px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      font-weight: 400 !important;
      font-size: 22px;
      line-height: 24px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      font-size: 18px;
      line-height: 20px;
    }
  }
}
