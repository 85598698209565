@import "mixins";
@import "vars";

.text-border {
  font-family: Joystix;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  text-shadow: -3px 0 white, 0 3px white, 3px 0 white, 0 -3px white, 3px 3px white, 3px -3px white, -3px 3px white,
    -3px -3px white;
}

.text-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 162px 0;

    @include breakpoint_MaxWidth($bp-1440) {
      padding: 92px 0 100px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      padding: 68px 0 69px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      padding: 46px 45px;
    }
  }

  &__title {
    font-size: 64px;
    line-height: 77px;

    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 48px;
      line-height: 58px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      font-size: 36px;
      line-height: 43px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__body {
    flex-grow: 1;
    width: 100%;
    padding: 0 15.625%;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 35px;

    @include breakpoint_MaxWidth($bp-1440) {
      padding: 0 48px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      padding: 0;
    }
  }
}
