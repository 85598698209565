@import "../../shared/styles/vars";
@import "../../shared/styles/mixins";


.award-rule-page {
  .award-example {
    text-align: center;

    & img {
      @include breakpoint_MaxWidth($bp-1100) {
        max-width: 550px;
      }
      @include breakpoint_MaxWidth($bp-1024) {
        max-width: 500px;
      }
      @include breakpoint_MaxWidth($bp-900) {
        max-width: 400px;
      }
      @include breakpoint_MaxWidth($mobile-album) {
        max-width: 350px;
      }
      @include breakpoint_MaxWidth($mobile-l) {
        max-width: 300px;
      }
      @include breakpoint_MaxWidth($mobile-390) {
        max-width: 250px;
      }
    }

    figcaption {
      font-style: italic;

      @include breakpoint_MaxWidth($tablet) {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}
