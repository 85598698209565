.ant-pagination {
  &-item {
    background-color: transparent !important;
    border-color: #ff4d07 !important;
    height: 36px !important;
    min-width: 36px !important;
    border-radius: 0 !important;

    a {
      color: black !important;
      height: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
      font-size: 18px;
      line-height: 21px;
    }

    &:hover {
      background-color: #c03500 !important;
      border-color: #c03500 !important;
      a {
        color: white !important;
      }
    }

    &-active {
      background-color: #ff4d07 !important;
      border-color: #ff4d07 !important;
      a {
        color: white !important;
      }
    }
  }

  &-jump-next,
  &-jump-prev {
    height: 36px !important;
    min-width: 20px;
    .ant-pagination-item-container {
      height: 100%;

      .anticon-double-right,
      .anticon-double-left {
        color: white;
        font-size: 16px;
        padding-top: 10px;
      }

      .ant-pagination-item-ellipsis {
        color: white;
        padding-top: 12px;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    height: 36px;

    button {
      height: 36px;
      background-color: #ff4d07;
      border-color: #ff4d07;
      color: white;
      font-size: 18px;
      line-height: 21px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &:hover {
      button {
        background-color: #c03500;
        border-color: #c03500;
        color: white;
      }
    }
  }

  .ant-pagination-disabled {
    button {
      background-color: transparent;
      border-color: #d9d9d9;
      color: #d9d9d9;
    }

    &:hover {
      button {
        background-color: transparent;
        border-color: #d9d9d9;
        color: #d9d9d9;
      }
    }
  }
}
