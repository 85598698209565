@import "../../styles/vars";
@import "../../styles/mixins";

.text-page-layout {
  &__header {
    background-image: url("../../../assets/images/aboutTheAward/about-the-award-header.png");
    color: #ff4802;

    @include breakpoint_MaxWidth($bp-1440) {
      background: url("../../../assets/images/aboutTheAward/header-bg-1366.webp");
    }
    @include breakpoint_MaxWidth($bp-900) {
      background: url("../../../assets/images/aboutTheAward/header-bg-768.png");
    }
    @include breakpoint_MaxWidth($mobile-390) {
      background: url("../../../assets/images/aboutTheAward/header-bg-360.png");
    }
  }

  &__content {
    background-image: url("../../../assets/images/aboutTheAward/text-page-bg-1920.webp");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
    background-size: cover;
    position: relative;
  }

  &__footer-image {
    background-image: url("../../../assets/images/main/prizes-top.png");
    height: 181px;
    transform: rotate(180deg);
    position: absolute;
    width: 100%;
    bottom: 0;

    @include breakpoint_MaxWidth($bp-1440) {
      height: 138px;
      background-size: cover;
      background-position: center;
    }
    @include breakpoint_MaxWidth($bp-900) {
      height: 73px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      height: 34px;
    }
  }
}
