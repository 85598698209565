@import "../../shared/styles/vars";
@import "../../shared/styles/mixins";

.winners-page {
  &__header {
    background-image: url("../../assets/images/winners/winners-page-header.jpg");
    color: #1c2fed;
  }

  &__content {
    .winners-block {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-bottom: 10px solid #000000;
      //padding: 64px 95px 102px 95px;

      &__title {
        font-family: Joystix, sans-serif;
        font-size: 96px;
        line-height: 115px;
        color: #000000;
        padding: 56px 80px 24px;

        @include breakpoint_MaxWidth($bp-1440) {
          padding: 36px 36px 16px;
          font-size: 72px;
          line-height: 86px;
        }
        @include breakpoint_MaxWidth($bp-900) {
          padding: 36px 36px 24px;
          font-size: 56px;
          line-height: 68px;
        }
        @include breakpoint_MaxWidth($mobile-l) {
          padding: 24px 16px 12px;
          font-size: 40px;
          line-height: 48px;
        }
      }

      &__subtitle {
        font-size: 32px;
        line-height: 38px;
        font-weight: 700;
        color: #FFFFFF;
        text-align: center;

        @include breakpoint_MaxWidth($bp-1440) {
          font-size: 24px;
          line-height: 28px;
        }
        @include breakpoint_MaxWidth($bp-900) {
          font-size: 18px;
          line-height: 21px;
        }

        &-left {
          text-align: left;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          color: #FFFFFF;

          @include breakpoint_MaxWidth($bp-1440) {
            font-size: 20px;
            line-height: 24px;
          }
          @include breakpoint_MaxWidth($bp-900) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }

      .year-winners {
        padding: 56px 310px 55px;
        background: rgba(255, 72, 2, 0.6);
        display: flex;
        justify-content: space-between;

        @include breakpoint_MaxWidth($bp-1840) {
          padding: 56px 230px 55px;
        }
        @include breakpoint_MaxWidth($bp-1768) {
          padding: 56px 180px 55px;
        }
        @include breakpoint_MaxWidth($bp-1680) {
          padding: 56px 130px 55px;
        }
        @include breakpoint_MaxWidth($bp-1536) {
          padding: 56px 80px 55px;
        }
        @include breakpoint_MaxWidth($bp-1440) {
          padding: 48px 142px;
        }
        @include breakpoint_MaxWidth($bp-1330) {
          padding: 48px 120px;
        }
        @include breakpoint_MaxWidth($bp-1280) {
          padding: 48px 58px;
        }
        @include breakpoint_MaxWidth($bp-1100) {
          padding: 48px 100px;
        }
        @include breakpoint_MaxWidth($bp-900) {
          padding: 36px 36px;
        }
        @include breakpoint_MaxWidth($mobile-765) {
          padding: 36px 28px;
        }
        @include breakpoint_MaxWidth(710px) {
          flex-direction: column;
          align-items: center;
          gap: 48px;
        }
        @include breakpoint_MaxWidth($mobile-l) {
          padding: 24px;
        }
        @include breakpoint_MaxWidth($mobile-xs) {
          gap: 16px;
        }

        &__winners {
          display: flex;
          gap: 16px;

          @include breakpoint_MaxWidth($mobile-xs) {
            gap: 10px;
          }
        }

        &__title {
          writing-mode: vertical-lr;
          transform: scale(-1, -1);
          font-family: Joystix, sans-serif;
          font-weight: 400;
          font-size: 48px;
          line-height: 58px;
          color: #FFDE75;
          //margin-bottom: 24px;
          align-self: flex-start;

          @include breakpoint_MaxWidth($bp-1440) {
            font-size: 40px;
            line-height: 48px;
          }
          @include breakpoint_MaxWidth($bp-1100) {
            font-size: 34px;
            line-height: 36px;
          }
          @include breakpoint_MaxWidth($bp-1024) {
            font-size: 30px;
          }
          @include breakpoint_MaxWidth($mobile-album) {
            writing-mode: vertical-lr;
            transform: scale(-1, -1);
          }
          @include breakpoint_MaxWidth($mobile-l) {
            font-size: 30px;
            line-height: 36px;
          }
        }

        &__content {
          //margin-left: 16px;
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: space-between;
          gap: 56px;

          @include breakpoint_MaxWidth($bp-1440) {
            gap: 30px;
          }
          @include breakpoint_MaxWidth($bp-900) {
            gap: 18px;
          }
          @include breakpoint_MaxWidth(710px) {
            flex-direction: column;
            gap: 18px;
            justify-content: space-between;
            width: unset;
          }
        }


        &__group {
          gap: 40px;
          display: flex;
          justify-content: space-between;

          @include breakpoint_MaxWidth($bp-1100) {
            gap: 24px;
          }

        }

        & .year-winner-image {
          width: 500px;
          height: 550px;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 12px;
          cursor: pointer;
          transition: all .15s linear;

          @include breakpoint_MaxWidth($bp-1440) {
            width: 420px;
            height: 462px;
            margin-bottom: 10px;
          }
          @include breakpoint_MaxWidth($bp-1100) {
            max-width: 320px;
            max-height: 351px;
          }
          @include breakpoint_MaxWidth($bp-1024) {
            max-width: 268px;
            max-height: 295px;
          }
          @include breakpoint_MaxWidth($bp-900) {
            margin-bottom: 4px;
          }
          @include breakpoint_MaxWidth($mobile-xs) {
            max-width: 240px;
            max-height: 264px;
          }

          &:hover {
            opacity: 0.6;
          }

          &__image {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        & .year-sub-winner-image {
          width: 230px;
          height: 252px;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 12px;
          cursor: pointer;
          transition: all .15s linear;

          @include breakpoint_MaxWidth($bp-1440) {
            width: 190px;
            height: 210px;
            margin-bottom: 10px;
          }
          @include breakpoint_MaxWidth($bp-1100) {
            width: 140px;
            height: 154px;
          }
          @include breakpoint_MaxWidth($bp-1024) {
            max-width: 122px;
            max-height: 134px;
          }
          @include breakpoint_MaxWidth($bp-900) {
            max-width: 122px;
            max-height: 134px;
          }
          @include breakpoint_MaxWidth($mobile-xs) {
            max-width: 110px;
            max-height: 121px;
          }

          &:hover {
            opacity: 0.6;
          }

          &__image {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .single {
        padding: 48px 430px;
        flex-direction: column;
        align-items: center;
        justify-content: unset;

        @include breakpoint_MaxWidth($bp-1840) {
          padding: 48px 350px;
        }
        @include breakpoint_MaxWidth($bp-1768) {
          padding: 48px 300px;
        }
        @include breakpoint_MaxWidth($bp-1680) {
          padding: 48px 250px;
        }
        @include breakpoint_MaxWidth($bp-1536) {
          padding: 48px 200px;
        }
        @include breakpoint_MaxWidth($bp-1440) {
          padding: 48px 234px;
        }
        @include breakpoint_MaxWidth($bp-1330) {
          padding: 48px 180px;
        }
        @include breakpoint_MaxWidth($bp-1280) {
          padding: 48px 98px;
        }
        @include breakpoint_MaxWidth($bp-900) {
          padding: 36px 98px;
        }
        @include breakpoint_MaxWidth($mobile-765) {
          padding: 36px 28px;
        }
        @include breakpoint_MaxWidth(710px) {
          flex-direction: row;
          align-items: unset;
          justify-content: center;
          gap: 20px;
        }
        @include breakpoint_MaxWidth($mobile-l) {
          padding: 24px;
        }
        @include breakpoint_MaxWidth($mobile-xs) {
          gap: 16px;
        }

        & .year-winners__winners {
            display: block;
            gap: unset;

          @include breakpoint_MaxWidth(710px) {
            display: flex;
            gap: 24px;
          }
          @include breakpoint_MaxWidth($mobile-xs) {
            gap: 10px;
          }
        }

        & .year-winners__title {
          writing-mode: unset;
          transform: unset;
          margin-bottom: 24px;

          @include breakpoint_MaxWidth($bp-1440) {
            font-size: 40px;
            line-height: 48px;
          }
          @include breakpoint_MaxWidth($bp-1100) {
            font-size: 34px;
            line-height: 36px;
          }
          @include breakpoint_MaxWidth($bp-1024) {
            font-size: 30px;
          }
          @include breakpoint_MaxWidth(710px) {
            writing-mode: vertical-lr;
            transform: scale(-1, -1);
            margin-bottom: unset;
          }
          @include breakpoint_MaxWidth($mobile-l) {
            font-size: 30px;
            line-height: 36px;
          }
        }

        & .year-winners__content {
          flex-direction: row;
          gap: 66px;

          @include breakpoint_MaxWidth($bp-1440) {
            gap: 64px;
          }
          @include breakpoint_MaxWidth($bp-900) {
            gap: 36px;
          }
          @include breakpoint_MaxWidth(710px) {
            flex-direction: column;
            gap: 18px;
            justify-content: space-between;
            width: unset;
          }
        }
      }

      .month-winners {
        padding: 48px 180px 68px;

        @include breakpoint_MaxWidth($bp-1840) {
          padding: 48px 100px 68px;
        }
        @include breakpoint_MaxWidth($bp-1680) {
          padding: 48px 60px 68px;
        }
        @include breakpoint_MaxWidth($bp-1536) {
          padding: 48px 40px 68px;
        }
        @include breakpoint_MaxWidth($bp-1440) {
          padding: 36px 38px 48px;
        }
        @include breakpoint_MaxWidth($bp-900) {
          padding: 36px 25px;
        }
        @include breakpoint_MaxWidth($mobile-l) {
          padding: 24px 16px 36px;
        }

        &__title {
          font-family: 'Joystix';
          font-style: normal;
          font-weight: 400;
          font-size: 48px;
          line-height: 58px;
          color: #FF4802;
          margin-bottom: 30px;

          @include breakpoint_MaxWidth($bp-1440) {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 24px;
          }
          @include breakpoint_MaxWidth($bp-900) {
            font-size: 30px;
            line-height: 36px;
          }

          &-center {
            text-align: center;
            font-family: 'Joystix';
            font-style: normal;
            font-weight: 400;
            font-size: 48px;
            line-height: 58px;
            color: #FF4802;
            margin-bottom: 30px;

            @include breakpoint_MaxWidth($bp-1440) {
              font-size: 40px;
              line-height: 48px;
              margin-bottom: 24px;
            }
            @include breakpoint_MaxWidth($bp-900) {
              font-size: 30px;
              line-height: 36px;
            }
            @include breakpoint_MaxWidth($mobile-album) {
              text-align: left;
            }
          }
        }

        &__content {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          grid-column-gap: 36px;
          grid-row-gap: 56px;

          @include breakpoint_MaxWidth($bp-1440) {
            grid-column-gap: 30px;
            grid-row-gap: 36px;
          }
          @include breakpoint_MaxWidth($bp-1100) {
            grid-template-columns: repeat(5, 1fr);
            grid-column-gap: 27px;
            grid-row-gap: 24px;
          }
          @include breakpoint_MaxWidth($mobile-765) {
            grid-template-columns: repeat(4, 1fr);
          }
          @include breakpoint_MaxWidth($mobile-album) {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;
            grid-row-gap: 20px;
          }

          &-min {
            display: flex;
            justify-content: center;
            gap: 36px;

            @include breakpoint_MaxWidth($bp-1440) {
              gap: 78px;
            }
            @include breakpoint_MaxWidth($bp-1100) {
              flex-wrap: wrap;
            }
            @include breakpoint_MaxWidth($bp-1024) {
              gap: 27px;
            }
            @include breakpoint_MaxWidth($mobile-album) {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-column-gap: 30px;
              grid-row-gap: 20px;
            }
          }
        }

        &__card {

          &-min {
            max-width: 228px;

            @include breakpoint_MaxWidth($bp-1440) {
              max-width: 188px;
            }
            @include breakpoint_MaxWidth($bp-1024) {
              max-width: 145px;
            }
            @include breakpoint_MaxWidth($bp-900) {
              max-width: 122px;
            }
            @include breakpoint_MaxWidth($mobile-album) {
              max-width: unset;
            }
          }

          &-image {
            width: 100%;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 12px;
            cursor: pointer;
            transition: all .15s linear;

            @include breakpoint_MaxWidth($bp-1440) {
              margin-bottom: 8px;
            }

            &:hover {
              opacity: 0.6;
            }

            & img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          &-title {
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            color: #000000;

            @include breakpoint_MaxWidth($bp-900) {
              font-size: 16px;
              line-height: 19px;
            }
          }
        }

      }
    }
  }

  & .disable {
    cursor: not-allowed !important;

    &:hover {
      opacity: 1 !important;
    }
  }
}
