@import "../../../node_modules/antd/dist/antd.compact.min";
@import "fonts";
@import "classes";
@import "modal";
@import "vars";
@import "mixins";

body {
  font-family: "Roboto", "sans-serif";
  margin: 0 !important;
  color: white;
  overflow-x: hidden;
}

.App {
  width: 100vw;
  position: relative;
}

//.page-layout {
//  width: 100%;
//  height: 100%;
//  position: relative;
//  display: flex;
//  flex-direction: column;
//}

.ant-btn {
  background: #ff00ff;
  border: 1px solid #ffffff;
  span {
    font-weight: 500;
  }
  &:hover,
  &:focus {
    background: #ad04ad;
    border: 1px solid #ffffff;
    cursor: pointer;
  }
}

.ant-back-top {
  width: 50px;
  height: 50px;
  right: 50px;
  bottom: 40px;

  @include breakpoint_MaxWidth($mobile-album) {
    width: 34px;
    height: 34px;
    right: 18px;
    bottom: 26px;
  }

  & .shadow-button {
    width: 100%;
    height: 100%;
    padding: 0 !important;

    &__top, &__back {
      width: 100%;
      height: 100%;
      padding: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__top {
      &:hover {
        @include breakpoint_MaxWidth($mobile-album) {
          right: -2px;
          bottom: -2px;
        }
      }
    }

    &__back {
      @include breakpoint_MaxWidth($mobile-album) {
        right: -2px;
        bottom: -2px;
      }
    }

    & .top-arrow svg {
      width: 28px;
      height: 22px;

      @include breakpoint_MaxWidth($mobile-album) {
        width: 18px;
        height: 14px;
      }
    }
  }
}
