@import "../../../../../../shared/styles/vars";
@import "../../../../../../shared/styles/mixins";

.vote-now {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  &__title {
    font-size: 72px;
    line-height: 86px;
    color: #1c2fed;
    text-align: center;

    @include breakpoint_MaxWidth($bp-1680) {
      font-size: 56px;
      line-height: 62px;
    }
    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 36px;
      line-height: 44px;
    }
  }

  &__month {
    font-size: 64px;
    line-height: 77px;
    color: #ff00ff;

    @include breakpoint_MaxWidth($bp-1680) {
      font-size: 56px;
      line-height: 62px;
    }
    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 36px;
      line-height: 44px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
    }
  }

  &__button.ant-btn {
    height: 94px;
    background: #1c2fed;
    padding-left: 60px;
    padding-right: 60px;

    @include breakpoint_MaxWidth($bp-1440) {
      padding: 12.5px 42px;
      height: unset;
    }
    @include breakpoint_MaxWidth($bp-900) {
      margin-top: 24px;
      padding: 10px 32px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      margin-top: 16px;
    }

    span {
      font-size: 48px;
      line-height: 56px;
      color: #ffffff;

      @include breakpoint_MaxWidth($bp-1440) {
        font-size: 30px;
        line-height: 36px;
      }
      @include breakpoint_MaxWidth($bp-900) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &:hover,
    &:focus {
      background: #1d2787;
    }
  }
}
