@import "../../../shared/styles/vars";
@import "../../../shared/styles/mixins";

.footer {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 0;
  gap: 14px;
  position: relative;

  @include breakpoint_MaxWidth($bp-900) {
    gap: 8px;
    padding: 16px 0 18px;
  }
  @include breakpoint_MaxWidth($mobile-390) {
    padding: 16px 0 24px;
  }

  &__title {
    font-family: Joystix;
    display: flex;
    align-items: center;
    gap: 25px;
    font-size: 36px;
    line-height: 43px;
    margin-top: 10px;

    @include breakpoint_MaxWidth($bp-900) {
      margin-top: 2px;
      font-size: 24px;
      line-height: 28px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      font-size: 18px;
      line-height: 21px;
      gap: 16px;
    }
  }

  &__subtitle {
    color: white;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;

    @include breakpoint_MaxWidth($bp-900) {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 19px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__links {
    display: flex;
    gap: 4.4rem;

    @include breakpoint_MaxWidth($bp-900) {
      gap: 30px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      gap: 24px;
    }

    a {
      color: white;

      @include breakpoint_MaxWidth($bp-900) {
        font-size: 16px;
        line-height: 19px;
      }
      @include breakpoint_MaxWidth($mobile-390) {
        font-size: 14px;
        line-height: 16px;
      }

      &:hover {
        color: #ff00ff;
        text-decoration: none;
      }
    }

    span {
      color: white;
      font-size: 1.1rem;
      line-height: 1.3rem;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: #ff00ff;
        text-decoration: none;
      }
    }
  }

  &__social {
    display: flex;
    gap: 2.5rem;
    margin-top: 10px;
    align-items: center;

    @include breakpoint_MaxWidth($bp-900) {
      margin-top: 7px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      margin-top: 13px;
    }

    a {
      color: white;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 19px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    color: white;

    @include breakpoint_MaxWidth($bp-900) {
      margin-top: 14px;
      font-size: 14px;
      line-height: 16px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      max-width: 224px;
      text-align: center;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.twitter-icon {
  font-size: 2rem;
  cursor: pointer;
  &:hover {
    svg {
      fill: #ff00ff;
    }
  }

  & svg {
    @include breakpoint_MaxWidth($bp-900) {
      width: 27px;
      height: 22px;
    }
  }
}

.telegram-icon {
  font-size: 2rem;
  cursor: pointer;
  &:hover {
    svg {
      fill: #ff00ff;
    }
  }

  & svg {
    @include breakpoint_MaxWidth($bp-900) {
      width: 27px;
      height: 23px;
    }
  }
}

.mastodon-icon {
  font-size: 2rem;
  cursor: pointer;
  &:hover {
    svg {
      fill: #ff00ff;
    }
  }

  & svg {
    @include breakpoint_MaxWidth($bp-900) {
      width: 26px;
      height: 27px;
    }
  }
}

.discord-icon {
  font-size: 2rem;
  cursor: pointer;
  &:hover {
    svg path {
      fill: #ff00ff;
    }
  }

  & svg {
    width: 35px;
    height: 35px;

    @include breakpoint_MaxWidth($bp-900) {
      width: 27px;
      height: 27px;
    }
  }
}

//.instagram-icon {
//  font-size: 2rem;
//  cursor: pointer;
//  &:hover {
//    svg {
//      fill: #ff00ff;
//    }
//  }
//}

//.medium-icon {
//  font-size: 3.2rem;
//  cursor: pointer;
//  padding-bottom: 3px;
//  &:hover {
//    color: #ff00ff;
//  }
//}

.ooyoot-logo {
  background: url("../../../assets/images/gatia_labs_logo.png") no-repeat left bottom/contain;
  width: 449px;
  height: 107px;
  cursor: pointer;

  @include breakpoint_MaxWidth($bp-900) {
    width: 311px;
    height: 74px;
  }
  @include breakpoint_MaxWidth($mobile-390) {
    width: 229px;
    height: 54px;
  }
}

.top-arrow {
  font-size: 2rem;
}
