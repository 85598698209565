@import "../../../../shared/styles/vars";
@import "../../../../shared/styles/mixins";

.prizes-block {
  background-image: url("../../../../assets/images/main/prizes.png");

  @include breakpoint_MaxWidth($bp-1440) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  @include breakpoint_MaxWidth($bp-900) {
    background-image: url("../../../../assets/images/main/prizes-bg-768.webp");
    background-size: cover;
  }

  .ant-row {
    flex-flow: nowrap;
  }

  &__top {
    background-image: url("../../../../assets/images/main/prizes-top.png");
    height: 182px;

    @include breakpoint_MaxWidth($bp-1440) {
      height: 128px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    @include breakpoint_MaxWidth($bp-900) {
      height: 73px;
    }
    @include breakpoint_MaxWidth($mobile-album) {
      height: 64px;
    }
  }

  &__content {
    padding: 32px 170px 60px;

    @include breakpoint_MaxWidth($bp-1840) {
      padding: 32px 120px 60px;
    }
    @include breakpoint_MaxWidth($bp-1680) {
      padding: 32px 100px 60px;
    }
    @include breakpoint_MaxWidth($bp-1440) {
      padding: 24px 74px 48px;
    }
    @include breakpoint_MaxWidth($bp-1280) {
      padding: 24px 54px 48px;
    }
    @include breakpoint_MaxWidth($bp-1100) {
      padding: 24px 44px 48px;
    }
    @include breakpoint_MaxWidth($bp-1024) {
      padding: 24px 34px 48px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      padding: 8px 24px 35px;
    }
    @include breakpoint_MaxWidth($mobile-album) {
      padding: 16px 16px 36px;
    }
  }

  .block-image {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 490px;
    max-height: 480px;
    padding: 0 !important;

    @include breakpoint_MaxWidth($bp-1440) {
      max-width: 337px;
      max-height: 330px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      max-width: 204px;
      max-height: 200px;
    }
    @include breakpoint_MaxWidth($mobile-album) {
      max-width: 240px;
      max-height: 235px;
    }
  }

  &__title {
    font-family: Joystix;
    font-size: 48px;
    line-height: 58px;
    color: #fec200;
    text-align: center;
    margin-bottom: 90px;

    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 24px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 16px;
    }
    @include breakpoint_MaxWidth($mobile-album) {
      margin-bottom: 24px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & br {
      display: none;
    }

    a {
      color: #fec200;

      &:hover {
        color: #fec200;
        text-decoration: underline;
      }
    }

    .text-title {
      font-family: Joystix;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
      margin-bottom: 18px;

      @include breakpoint_MaxWidth($bp-900) {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 8px;
      }
      @include breakpoint_MaxWidth($mobile-xs) {
        font-size: 15px;
      }
    }

    p {
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      font-weight: 400;

      @include breakpoint_MaxWidth($bp-1440) {
        font-size: 16px;
        line-height: 19px;
      }
      @include breakpoint_MaxWidth($bp-900) {
        font-size: 14px;
        line-height: 16px;
      }
      @include breakpoint_MaxWidth($mobile-album) {
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }

    }
  }

  .ant-row {
    padding: 0 !important;
    margin:0 !important;
    justify-content: space-between !important;
    gap: 64px;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 60px !important;

      @include breakpoint_MaxWidth($bp-1440) {
        margin-bottom: 48px !important;
      }
      @include breakpoint_MaxWidth($mobile-album) {
        margin-bottom: 36px !important;
      }
    }



    @include breakpoint_MaxWidth($bp-1440) {
      gap: 36px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      gap: 24px;
    }
    @include breakpoint_MaxWidth($mobile-album) {
      flex-direction: column !important;
      row-gap: 24px !important;
    }

    &:nth-child(3) {
      @include breakpoint_MaxWidth($mobile-album) {
        flex-direction: column-reverse !important;
      }
    }
    &:last-of-type {
      @include breakpoint_MaxWidth($mobile-album) {
        flex-direction: column-reverse !important;
      }
    }
  }

  .ant-col-14 {
    padding: 0 !important;
    flex: unset !important;
    max-width: unset !important;
  }
}
