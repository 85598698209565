@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

/* joystix */
@font-face {
  font-family: "Joystix";
  src: local(""), url("../../assets/fonts/joystix-monospace.otf") format("opentype"),
    /* Modern Browsers */ url("../../assets/fonts/joystix-monospace.ttf") format("truetype");
  font-display: swap;
}

/* roboto-100 - cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("../../assets/fonts/roboto-v20-cyrillic-100.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/roboto-v20-cyrillic-100.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/roboto-v20-cyrillic-100.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-100.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-100.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/roboto-v20-cyrillic-100.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}
/* roboto-100italic - cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  src: url("../../assets/fonts/roboto-v20-cyrillic-100italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/roboto-v20-cyrillic-100italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/roboto-v20-cyrillic-100italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-100italic.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-100italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/roboto-v20-cyrillic-100italic.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}
/* roboto-300 - cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../../assets/fonts/roboto-v20-cyrillic-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/roboto-v20-cyrillic-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/roboto-v20-cyrillic-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-300.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/roboto-v20-cyrillic-300.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}
/* roboto-300italic - cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: url("../../assets/fonts/roboto-v20-cyrillic-300italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/roboto-v20-cyrillic-300italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/roboto-v20-cyrillic-300italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-300italic.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-300italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/roboto-v20-cyrillic-300italic.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}
/* roboto-regular - cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/roboto-v20-cyrillic-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/roboto-v20-cyrillic-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/roboto-v20-cyrillic-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-regular.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/roboto-v20-cyrillic-regular.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}
/* roboto-italic - cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("../../assets/fonts/roboto-v20-cyrillic-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/roboto-v20-cyrillic-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/roboto-v20-cyrillic-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-italic.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/roboto-v20-cyrillic-italic.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}
/* roboto-500 - cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/roboto-v20-cyrillic-500.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/roboto-v20-cyrillic-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/roboto-v20-cyrillic-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-500.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/roboto-v20-cyrillic-500.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}
/* roboto-500italic - cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("../../assets/fonts/roboto-v20-cyrillic-500italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/roboto-v20-cyrillic-500italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/roboto-v20-cyrillic-500italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-500italic.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-500italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/roboto-v20-cyrillic-500italic.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}
/* roboto-700 - cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/roboto-v20-cyrillic-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/roboto-v20-cyrillic-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/roboto-v20-cyrillic-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-700.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/roboto-v20-cyrillic-700.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}
/* roboto-700italic - cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("../../assets/fonts/roboto-v20-cyrillic-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/roboto-v20-cyrillic-700italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/roboto-v20-cyrillic-700italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-700italic.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-700italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/roboto-v20-cyrillic-700italic.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}
/* roboto-900 - cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/fonts/roboto-v20-cyrillic-900.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/roboto-v20-cyrillic-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/roboto-v20-cyrillic-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-900.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-900.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/roboto-v20-cyrillic-900.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}
/* roboto-900italic - cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  src: url("../../assets/fonts/roboto-v20-cyrillic-900italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/roboto-v20-cyrillic-900italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/roboto-v20-cyrillic-900italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-900italic.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/roboto-v20-cyrillic-900italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../assets/fonts/roboto-v20-cyrillic-900italic.svg#Roboto") format("svg"); /* Legacy iOS */
  font-display: swap;
}
