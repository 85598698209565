@import "../../../shared/styles/vars";
@import "../../../shared/styles/mixins";

.header {
  height: 90px;
  padding: 21px 80px 26px;
  display: flex;
  justify-content: space-between;
  background-color: black;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 5;

  @include breakpoint_MaxWidth($bp-1440) {
    padding: 16px 36px;
    height: 70px;
  }
  @include breakpoint_MaxWidth($bp-900) {
    padding: 6px 24px;
    height: 52px;
  }
  @include breakpoint_MaxWidth($mobile-390) {
    padding: 6px 16px;
  }

  &__title {
    font-family: Joystix, sans-serif;
    display: flex;
    align-items: center;
    width: 448px;
    justify-content: space-between;
    font-size: 36px;
    line-height: 43px;
    cursor: pointer;

    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 32px;
      line-height: 38px;
      width: 398px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      width: unset;
      flex-direction: column;
      font-size: 13px;
      line-height: 14px;
      align-items: flex-start;
    }
  }

  &__actions {
    display: flex;
    height: 100%;
    align-items: center;

    @include breakpoint_MaxWidth($mobile-480) {
      display: none;
    }

    .nav-links {
      color: white;
      font-size: 18px;
      line-height: 21px;

      @include breakpoint_MaxWidth($bp-1024) {
        font-size: 16px;
        line-height: 19px;
      }

      ul {
        display: flex;
        gap: 36px;
        margin: 0;
        padding: 0;

        @include breakpoint_MaxWidth($bp-1440) {
          gap: 24px;
        }
        @include breakpoint_MaxWidth($mobile-xl) {
          gap: 20px;
        }

        li {
          list-style-type: none;
          cursor: pointer;
          align-self: center;

          a {
            color: white;

            &:hover {
              color: #ff4802;
            }
          }

          button {
            height: 40px;
            background-color: #ff4802;
            padding-left: 30px;
            padding-right: 30px;

            span {
              font-size: 18px;
              line-height: 21px;
              color: white;
            }

            &:hover {
              background-color: #c03500;
            }
          }
        }
      }
    }
  }

  &__btn {
    display: none !important;
    & svg {
      width: 26px;
      height: 22px;
    }
    @include breakpoint_MaxWidth($mobile-480) {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
}

.burger-menu {
  position: fixed;
  width: 183px;
  right: -250px;
  top: 0;
  background-color: #333333;
  display: flex;
  flex-direction: column;
  padding: 17px 16px 24px;
  transition: right .35s linear;

  &__showed {
    right: 0;
  }
  &__close {
    width: 14px;
    height: 14px;
    align-self: flex-start;
    margin-bottom: 25px;

    &:hover {
      & svg path {
        fill: #FF4802;
      }
    }
  }

  &__link {
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;

    &:hover {
      color: #FF4802;
    }

    &:not(:last-of-type) {
      padding-bottom: 12px;
      border-bottom: 1px solid #4F4F4F;
      margin-bottom: 12px;
    }
  }
}
