@import "../../shared/styles/vars";
@import "../../shared/styles/mixins";

.vote-page {
  &__header {
    background-image: url("../../assets/images/vote/vote-page-header.png");
    color: #ff00ff;
  }

  &__content {
    padding: 56px 12% 0;
    color: black;

    @include breakpoint_MaxWidth($bp-1768) {
      padding: 56px 8% 0;
    }
    @include breakpoint_MaxWidth($bp-1536) {
      padding: 56px 47px 0;
    }
    @include breakpoint_MaxWidth($bp-1440) {
      padding: 48px 47px 0;
    }
    @include breakpoint_MaxWidth($bp-900) {
      padding: 56px 24px 0;
    }
    @include breakpoint_MaxWidth($mobile-480) {
      padding: 44px 16px 0;
    }

    &:nth-child(2) {
      padding: 48px 12% 0;

      @include breakpoint_MaxWidth($bp-1768) {
        padding: 48px 8% 0;
      }
      @include breakpoint_MaxWidth($bp-1536) {
        padding: 48px 47px 0;
      }
      @include breakpoint_MaxWidth($bp-1440) {
        padding: 36px 47px 0;
      }
      @include breakpoint_MaxWidth($bp-900) {
        padding: 24px 24px 0;
      }
      @include breakpoint_MaxWidth($mobile-480) {
        padding: 24px 16px 0;
      }

      & .ant-btn:active {
        & span {
          color: #F2994A;
        }
      }
    }
    &:nth-child(3) {
      & .vote-header__date {
        color: #47CFFE;
      }

      & .ant-btn {
        background-color: #47CFFE;

        &:hover {
          background-color: #43879F;
        }
      }
      & .ant-btn:active {
        & span {
          color: #47CFFE;
        }
      }
    }
    &:last-child {
      padding: 56px 12% 64px;

      @include breakpoint_MaxWidth($bp-1768) {
        padding: 56px 8% 64px;
      }
      @include breakpoint_MaxWidth($bp-1536) {
        padding: 56px 47px 64px;
      }
      @include breakpoint_MaxWidth($bp-1440) {
        padding: 48px 47px 48px;
      }
      @include breakpoint_MaxWidth($bp-900) {
        padding: 56px 24px;
      }
      @include breakpoint_MaxWidth($mobile-480) {
        padding: 44px 16px;
      }

      & .vote-header__date {
        color: #AD04AD;
      }

      & .ant-btn {
        background-color: #AD04AD;

        &:hover {
          background-color: #7D2264;
        }
      }
      & .ant-btn:active {
        & span {
          color: #BB6BD9;
        }
      }
    }

    .vote-header {
      &__date {
        font-family: Joystix, sans-serif;
        font-size: 36px;
        line-height: 43px;
        color: #ff4802;
        margin-bottom: 24px;

        @include breakpoint_MaxWidth($bp-1440) {
          margin-bottom: 20px;
        }
        @include breakpoint_MaxWidth($bp-900) {
          margin-bottom: 16px;
        }
        @include breakpoint_MaxWidth($mobile-480) {
          font-size: 24px;
          line-height: 29px;
        }
      }

      &__date-legend {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;

        @include breakpoint_MaxWidth($mobile-480) {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 4px;
        }
      }
    }

    .vote-body {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 57px;

      @include breakpoint_MaxWidth($bp-1680) {
        gap: 43px;
      }
      @include breakpoint_MaxWidth($bp-1280) {
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        gap: 39px;
        grid-row-gap: 48px;
      }
      @include breakpoint_MaxWidth($mobile-765) {
        gap: 24px;
        grid-row-gap: 44px;
      }
      @include breakpoint_MaxWidth($mobile-album) {
        grid-template-columns: 1fr 1fr;
      }
      @include breakpoint_MaxWidth(356px) {
        grid-template-columns: 1fr;
      }
    }
  }

  & .ant-btn[disabled] {
    background-color: #bdbdbd;
    cursor: not-allowed;
  }

  & .ant-btn {

    & span {
      @include breakpoint_MaxWidth($bp-900) {
        font-size: 16px;
        line-height: 19px;
      }
      @include breakpoint_MaxWidth($mobile-480) {
        font-size: 15px;
        line-height: 18px;
      }
    }

  }
}
