@import "../../../../../../shared/styles/vars";
@import "../../../../../../shared/styles/mixins";

.next-vote-timer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  width: 700px;

  @include breakpoint_MaxWidth($bp-1680) {
    width: 600px;
  }
  @include breakpoint_MaxWidth($bp-1440) {
    width: 500px;
  }
  @include breakpoint_MaxWidth($bp-900) {
    width: 400px;
  }
  @include breakpoint_MaxWidth($mobile-xl) {
    width: 350px;
  }
  @include breakpoint_MaxWidth($mobile-l) {
    width: 340px;
  }
  @include breakpoint_MaxWidth($mobile-390) {
    width: 300px;
  }
  @include breakpoint_MaxWidth($mobile-xs) {
    width: 280px;
  }
}
