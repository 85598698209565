.vote-modal.ant-modal {
  .ant-modal-content {
    border-radius: 3px;

    .ant-modal-close-x {
      svg {
        width: 26px;
        height: 26px;
        position: relative;
        top: 12px;
        right: 12px;

        &:hover {
          fill: #ff4802 !important;
        }
      }
    }
  }

  .ant-modal-body {
    height: 138px;
    border: 2px solid #ff4802;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .ant-modal-confirm-content {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #ff4802 !important;
  }

  .ant-modal-confirm-btns {
    display: none;
  }
}
