@import "../../shared/styles/vars";
@import "../../shared/styles/mixins";

.gallery-page {
  &__header {
    background-image: url("../../assets/images/gallery/gallery-header.png");
    color: #ff4802;
  }

  &__content {
    background-color: white;
    padding: 48px 80px 48px;

    @include breakpoint_MaxWidth($bp-1680) {
      padding: 48px 40px 48px;
    }
    @include breakpoint_MaxWidth($bp-1440) {
      padding: 36px;
    }
    @include breakpoint_MaxWidth($bp-1280) {
      padding: 24px 24px 36px;
      position: relative;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      padding: 24px 16px 36px;
    }

    & .ant-row {
      flex-wrap: unset;
      flex-flow: unset;

      @include breakpoint_MaxWidth($bp-1280) {
        padding-bottom: 76px;
      }
      @include breakpoint_MaxWidth($bp-900) {
        padding-bottom: 54px;
      }
      @include breakpoint_MaxWidth($mobile-765) {
        padding-top: 52px;
        padding-bottom: 52px;
      }
      @include breakpoint_MaxWidth($mobile-album) {
        flex-direction: column;
        row-gap: 16px !important;
        padding-bottom: 0;
      }
      @include breakpoint_MaxWidth($mobile-xl) {
        padding-bottom: 52px;
      }
    }

    & .ant-col-12 {
      @include breakpoint_MaxWidth($bp-1280) {
        position: unset;
        flex: unset !important;
        max-width: unset !important;
      }
    }

    & .ant-select {
      border-radius: 0 !important;

      @include breakpoint_MaxWidth($bp-900) {
        height: 36px !important;
      }
    }

    & .ant-select-selector {
      padding: 0 !important;
      border-radius: 0 !important;

      @include breakpoint_MaxWidth($bp-900) {
        height: 36px !important;
      }

      & .ant-select-selection-search {
        right: 0 !important;
        left: 0 !important;
      }

      & .ant-select-selection-item {
        padding: 0 16px !important;
        font-size: 18px !important;
        line-height: 21px !important;

        @include breakpoint_MaxWidth($bp-1440) {
          font-size: 16px !important;
          line-height: 19px !important;
        }
        @include breakpoint_MaxWidth($mobile-390) {
          padding: 0 8px !important;
        }
      }

      & .ant-select-selection-placeholder {
        padding: 0 16px !important;
        font-size: 18px !important;
        line-height: 21px !important;

        @include breakpoint_MaxWidth($bp-1440) {
          font-size: 16px !important;
          line-height: 19px !important;
        }
        @include breakpoint_MaxWidth($mobile-390) {
          padding: 0 8px !important;
        }
      }

    }

    & .ant-select-arrow {
      @include breakpoint_MaxWidth($bp-1440) {
        width: 14px !important;
        height: 14px !important;
      }
      @include breakpoint_MaxWidth($mobile-390) {
        right: 8px !important;
      }

      & .select-icon {

        @include breakpoint_MaxWidth($bp-1440) {
          font-size: 14px !important;
        }

      }
    }
  }

  &__cards-wrapper {
    margin: 36px 0 48px;

    @include breakpoint_MaxWidth($bp-1440) {
      margin: 36px 0;
    }
    @include breakpoint_MaxWidth($bp-900) {
      margin: 24px 0 30px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      margin: 24px 0;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 57px;
    grid-row-gap: 48px;

    @include breakpoint_MaxWidth($bp-1440) {
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 48px;
      grid-row-gap: 41px;
    }

    @include breakpoint_MaxWidth($bp-1024) {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 33px;
      grid-row-gap: 28px;
    }

    @include breakpoint_MaxWidth($mobile-album) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 24px;
      grid-row-gap: 24px;
    }
  }

  &__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & .ant-pagination {
      @include breakpoint_MaxWidth($mobile-l) {
        display: flex !important;
        justify-content: space-between !important;
        width: 100% !important;

        & li {
          margin-right: 6px;
        }
      }

      @include breakpoint_MaxWidth($mobile-390) {
        & li {
          width: 32px !important;
          height: 32px !important;
          min-width: unset !important;
        }
      }
      @include breakpoint_MaxWidth($mobile-xs) {
        & li {
          width: 28px !important;
          height: 28px !important;

        }
      }
    }

    & .ant-pagination-prev {
      margin-right: 16px;

      @include breakpoint_MaxWidth($mobile-l) {
        margin-right: 8px !important;
      }
    }

    & .ant-pagination-next {
      margin-left: 8px;
      margin-right: 0 !important;

      @include breakpoint_MaxWidth($mobile-l) {
        margin-left: 2px;
      }
    }
  }

  & .ant-col-8 {
    flex: unset !important;
    max-width: unset !important;

    @include breakpoint_MaxWidth($mobile-765) {
      position: unset;
    }
  }

  & .ant-btn {
    border-radius: 0 !important;
  }

  .ant-pagination-next {
    @include breakpoint_MaxWidth($mobile-xs){
      height: 28px !important;
      width: 28px !important;
    }
    & button {
      @include breakpoint_MaxWidth($mobile-xl) {
        display: none !important;
      }
    }
    &::after {
      @include breakpoint_MaxWidth($mobile-xl) {
        display: flex;
        align-items: center;
        content: "";
        background: url("../../assets/icons/pagin-right-arrow.svg") no-repeat center;
        background-color: #FF4802;
        width: 36px;
        height: 36px;
        border: 1px solid #FF4802;
      }
      @include breakpoint_MaxWidth($mobile-390) {
        width: 32px;
        height: 32px;
      }
      @include breakpoint_MaxWidth($mobile-xs){
        height: 28px !important;
        width: 28px !important;
      }
    }
  }

  .ant-pagination-prev {
    @include breakpoint_MaxWidth($mobile-xs){
      height: 28px !important;
      width: 28px !important;
    }
    & button {
      @include breakpoint_MaxWidth($mobile-xl) {
        display: none !important;
      }
    }
    &::after {
      @include breakpoint_MaxWidth($mobile-xl) {
        display: flex;
        align-items: center;
        content: "";
        background: url("../../assets/icons/pagin-left-arrow.svg") no-repeat center;
        background-color: #FF4802;
        color: white;
        width: 36px;
        height: 36px;
        border: 1px solid #FF4802;
      }
      @include breakpoint_MaxWidth($mobile-390) {
        width: 32px;
        height: 32px;
      }
      @include breakpoint_MaxWidth($mobile-xs){
        height: 28px !important;
        width: 28px !important;
      }
    }
  }
}
