@import "../../../../shared/styles/vars";
@import "../../../../shared/styles/mixins";

.gallery-item-card {
  color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 246px;
  //height: 100%;

  &__avatar {
    width: 100%;
    cursor: pointer;
    position: relative;
    margin-bottom: 16px;
    transition: all .15s linear;

    @include breakpoint_MaxWidth($bp-900) {
      margin-bottom: 10px;
    }

    &:hover {
      opacity: 0.3;
    }

    &_image {
      width: 100%;
      object-fit: contain;
    }
  }

  &__nft-name {
    display: flex;
    align-items: flex-end;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 8px;
    flex-grow: 1;

    @include breakpoint_MaxWidth($bp-900) {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 4px;
    }
  }

  &__artist-name {
    font-size: 16px;
    line-height: 19px;

    @include breakpoint_MaxWidth($bp-900) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
