@import "../../shared/styles/vars";
@import "../../shared/styles/mixins";

.text-content {
  font-size: 18px;
  line-height: 24px;
  width: 100%;
  padding: 64px 60px 215px;
  background: rgba(255, 255, 255, 0.3);

  @include breakpoint_MaxWidth($bp-1440) {
    padding: 36px 32px 174px 38px;
  }
  @include breakpoint_MaxWidth($bp-900) {
    padding: 36px 24px 102px;
  }
  @include breakpoint_MaxWidth($mobile-390) {
    padding: 36px 16px 57px;
  }

  &__title {
    font-family: Joystix;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    margin-bottom: 48px;

    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 55px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 20px;
    }
    @include breakpoint_MaxWidth($mobile-xs) {
      font-size: 23px;
    }
  }

  &__subtitle {
    font-family: Joystix;
    font-size: 24px;
    line-height: 29px;
    margin: 24px 0 16px;

    &:nth-child(1) {
      margin-top: 0;
    }

    @include breakpoint_MaxWidth($bp-900) {
      font-size: 16px;
      line-height: 19px;
      margin: 20px 0 8px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 24px;

    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  & p {
    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  a {
    color: #1C2FED;
    word-break: break-word;

    &:hover {
      color: #1C2FED;
      text-decoration: underline;
    }
  }
}


