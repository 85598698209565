.share-block {
  display: flex;
  gap: 8px;

  button {
    &:hover {
      transform: scale(1.1);
    }
  }

  &__icon {
    &:hover {
      transform: scale(1);
    }
  }
}

.share-block-tooltip.ant-tooltip {
  max-width: 600px;

  .ant-tooltip-arrow-content {
    background-color: white;
  }

  .ant-tooltip-inner {
    background-color: transparent;
    border-radius: 4px;
    padding: 0;
    box-shadow: none;
  }
}
