@import "src/shared/styles/mixins";

.a-select {
  width: 100%;

  .ant-select-selector {
    height: 52px !important;
    font-size: 18px;
    line-height: 21px;
    border: 2px solid #ff4802 !important;
    background-color: white !important;

    .ant-select-selection-search {
      input {
        height: 52px !important;
      }
    }

    .ant-select-selection-placeholder {
      font-size: 18px;
      line-height: 21px;
      align-self: center;
      color: #000000;
      padding-left: 17px !important;
    }

    .ant-select-selection-item {
      align-self: center;
      padding-left: 17px !important;
      padding-right: 26px;
    }
  }

  .ant-select-arrow {
    right: 16px;
    width: 16px;
    height: 16px;
  }

  .ant-select-clear {
    right: 16px;
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: rgba(255, 77, 7, 0.25);
    &:hover {
      color: #ff4802;
    }
  }
}

.ant-select-dropdown {
  padding: 0;

  .ant-select-item {
    min-height: 48px !important;
    border-bottom: 2px solid #ff4802 !important;
    border-left: 2px solid #ff4802 !important;
    border-right: 2px solid #ff4802 !important;
    border-top: none !important;

    &:first-child {
      border-top: 1px solid #ff4802 !important;
    }
    &:last-child {
      border-bottom: 1px solid #ff4802 !important;
    }

    &-option-content {
      font-size: 18px;
      line-height: 21px;
      align-self: center;
      padding-left: 9px;
    }
  }

  .ant-select-item-option-active {
    background-color: rgba(255, 77, 7, 0.15) !important;
  }

  .ant-select-item-option-selected {
    background-color: #ff4802 !important;
    color: white !important;
    font-weight: normal;
  }

  .ant-select-item-group {
    border-bottom: none !important;
    min-height: 20px !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    text-transform: uppercase;
    color: #ff4802;
    padding: 8px 14px;
  }
}

.select-icon {
  font-size: 16px;
}

.rc-virtual-list-holder {
  @include scrollbars(5px, #ff4802);
}

.ant-select-dropdown {
  padding: 0 !important;
}
