@import "../../../../../../shared/styles/vars";
@import "../../../../../../shared/styles/mixins";

.vote-start-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 50px;

  @include breakpoint_MaxWidth($bp-1440) {
    gap: unset;
  }

  &__title {
    font-size: 72px;
    line-height: 86px;
    color: #1c2fed;
    text-align: center;

    @include breakpoint_MaxWidth($bp-1680) {
      font-size: 56px;
      line-height: 62px;
    }
    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 18px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 8px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      margin-bottom: 6px;
    }
  }

  .ant-btn {
    height: 94px;
    padding-left: 60px;
    padding-right: 60px;

    @include breakpoint_MaxWidth($bp-1440) {
      padding: 12.5px 42px;
      height: unset;
    }
    @include breakpoint_MaxWidth($bp-900) {
      margin-top: 24px;
      padding: 10px 32px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      margin-top: 16px;
    }

    span {
      font-weight: 500;
      font-size: 48px;
      line-height: 56px;
      color: white;

      @include breakpoint_MaxWidth($bp-1440) {
        font-size: 30px;
        line-height: 36px;
      }
      @include breakpoint_MaxWidth($bp-900) {
        font-size: 24px;
        line-height: 28px;
      }

    }
  }

  .ant-row-center {
    @include breakpoint_MaxWidth($bp-900) {
      padding-top: 0 !important;
    }
  }
}
