@mixin scrollbars($size, $color) {
  overflow: auto;

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: #ff4802;
    border-radius: 3px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: $color;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 77, 7, 0.15);
  }
}

//Breakpoint mixins

@mixin breakpoint_MaxWidth($bp) {
  @media (max-width: $bp) {
    @content;
  }
}

@mixin breakpoint_MinWidth($bp) {
  @media (min-width: $bp) {
    @content;
  }
}
