@import "../../../../shared/styles/vars";
@import "../../../../shared/styles/mixins";

.vote-card {
  display: flex;
  flex-direction: column;

  & .gallery-item-card__artist-name {
    margin-bottom: 16px !important;
  }

  &__button {
    margin-top: auto;
    margin-bottom: 0 !important;
    display: flex;
    gap: 15px;
    align-items: center;

    @include breakpoint_MaxWidth($bp-900) {
      gap: 14px;
    }
    @include breakpoint_MaxWidth($mobile-480) {
      gap: 8px;
    }
  }

  &__vote-percent {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    color: #1C2FED;

    @include breakpoint_MaxWidth($bp-900) {
      font-size: 28px;
      line-height: 32px;
    }
    @include breakpoint_MaxWidth($mobile-480) {
      font-size: 20px;
      line-height: 23px;
    }

    &--leader {
      color: #FF4802;
    }
  }

  .ant-btn {
    background-color: #ff4802;
    height: 44px;
    padding-right: 42px;
    padding-left: 42px;
    width: min-content;

    @include breakpoint_MaxWidth($bp-900) {
      padding: 0 37.5px;
      height: 40px;
    }

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: white;
    }

    &:hover,
    &:focus {
      background-color: #c03500;
    }
  }

  .ant-btn[disabled] {
    background-color: #bdbdbd;
    cursor: not-allowed;

    &:hover {
      background-color: #bdbdbd;
    }
  }
}

.vote-card.voted {
  position: relative;

  .gallery-item-card__avatar {
    border: 3px solid #FF00FF;
  }
  .ant-btn {
    background-color: white !important;
    border: 3px solid #ff00ff;
    padding: 0 13.5px;

    @include breakpoint_MaxWidth($bp-900) {
      padding: 0 11.5px;
    }
    @include breakpoint_MaxWidth($mobile-480) {
      padding: 0 8px;
    }

    span {
      color: #ff00ff;
    }

    &:hover,
    &:focus {
      background-color: white;
      border: 3px solid #ff00ff;

      span {
        color: #ff00ff;
      }
    }
  }

  & .share-block {
    position: absolute;
    bottom: -35px;
  }
}
